import React from 'react';
import './Home.css';
import './Content.css';
import Legend from './Legend.js';
import additionalprocess1 from '../assets/Pangjai/additionalprocess-1.png';
import additionalprocess2 from '../assets/Pangjai/additionalprocess-2.png';
import additionalprocess3 from '../assets/Pangjai/additionalprocess-3.png';
import additionalprocess4 from '../assets/Pangjai/additionalprocess-4.png';
import additionalprocess5 from '../assets/Pangjai/additionalprocess-5.png';
import additionalprocess6 from '../assets/Pangjai/additionalprocess-6.png';
import agecomposition from '../assets/Pangjai/agecomposition.png';
import bags1 from '../assets/Pangjai/bags-1.png';
import bags2 from '../assets/Pangjai/bags-2.png';
import buildings from '../assets/Pangjai/buildings.png';
import color1 from '../assets/Pangjai/color-1.png';
import color2 from '../assets/Pangjai/color-2.png';
import color3 from '../assets/Pangjai/color-3.png';
import community1 from '../assets/Pangjai/community-1.png';
import community2 from '../assets/Pangjai/community-2.png';
import community3 from '../assets/Pangjai/community-3.png';
import community4 from '../assets/Pangjai/community-4.png';
import cover1 from '../assets/Pangjai/cover-1.png';
import cover2 from '../assets/Pangjai/cover-2.png';
import directory1 from '../assets/Pangjai/directory-1.png';
import directory2 from '../assets/Pangjai/directory-2.png';
import directoryfeedback1 from '../assets/Pangjai/directoryfeedback-1.png';
import directoryfeedback2 from '../assets/Pangjai/directoryfeedback-2.png';
import directoryfeedback3 from '../assets/Pangjai/directoryfeedback-3.png';
import final1 from '../assets/Pangjai/final-1.png';
import final2 from '../assets/Pangjai/final-2.png';
import final3 from '../assets/Pangjai/final-3.png';
import final4 from '../assets/Pangjai/final-4.png';
import final5 from '../assets/Pangjai/final-5.png';
import final6 from '../assets/Pangjai/final-6.png';
import final7 from '../assets/Pangjai/final-7.png';
import floorarea from '../assets/Pangjai/floorarea.png';
import history1 from '../assets/Pangjai/history-1.png';
import history2 from '../assets/Pangjai/history-2.png';
import history3 from '../assets/Pangjai/history-3.png';
import history4 from '../assets/Pangjai/history-4.png';
import history5 from '../assets/Pangjai/history-5.png';
import history6 from '../assets/Pangjai/history-6.png';
import history7 from '../assets/Pangjai/history-7.png';
import history8 from '../assets/Pangjai/history-8.png';
import householdcomposition from '../assets/Pangjai/householdcomposition.png';
import icons from '../assets/Pangjai/icons.png';
import problemverification1 from '../assets/Pangjai/problemverification-1.png';
import problemverification2 from '../assets/Pangjai/problemverification-2.png';
import problemverification3 from '../assets/Pangjai/problemverification-3.png';
import problemverification4 from '../assets/Pangjai/problemverification-4.png';
import problemverification5 from '../assets/Pangjai/problemverification-5.png';
import signage from '../assets/Pangjai/signage.png';
import signagemockup1 from '../assets/Pangjai/signagemockup-1.png';
import signagemockup2 from '../assets/Pangjai/signagemockup-2.png';
import sitevisit1 from '../assets/Pangjai/sitevisit-1.png';
import sitevisit2 from '../assets/Pangjai/sitevisit-2.png';
import sitevisit3 from '../assets/Pangjai/sitevisit-3.png';
import storecategories2 from '../assets/Pangjai/storecategories-2.png';
import storecategories from '../assets/Pangjai/storecategories.png';
import storenames from '../assets/Pangjai/storenames.png';
import workshop from '../assets/Pangjai/workshop.png';
import workshop2 from '../assets/Pangjai/workshop2.png';



const Home = () => {

    return (
        <div className="container content">

            <div className="row align-items-start ">
                {/* <div className="col-lg-6 col-md-12 left-column">
                    <img src={cover1} alt="cover" className="bottom-image" />
                </div> */}

                <div className="col-lg-12 col-md-12 left-column">
                    <img src={cover2} alt="cover" className="bottom-image" />
                </div>
            </div>

            <div className="row align-items-start">
                <div className="col-md-12 left-column">

                    <div className="text-content">

                        <h2>Research, Social Design</h2>
                        <h1>Pang Jai Fabric Market</h1>
                        {/* <h4>Teammates</h4><h4>
                            Jo Lai, Phoebe Hon, Charisse Kong, Na Leung, Ting Fong Leung, Tze Qiy Leung, Wylie Tam </h4>
                            <h4>Chun Wai Lai, Wing Man Lee, Tsz Yan Wong, Brandon Chow, Professor Patricia Choi (Supervisor)</h4> */}
                    </div>

                </div>

            </div>





            <div className="large-content-first">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>The Team
                            </h2>

                        </div>

                    </div>


                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>Abby Lui, Jo Lai, Phoebe Hon, Charisse Kong, Na Leung, Ting Fong Leung, Tze Qiy Leung, Wylie Tam, Chun Wai Lai, Wing Man Lee, Tsz Yan Wong, Brandon Chow, Professor Patricia Choi (Supervisor)
                            </h3>

                        </div>


                    </div>
                </div>
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>The Purpose
                            </h2>

                        </div>

                    </div>


                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>In 2023, I studied abroad at the Hong Kong Polytechnic University in the hopes of learning from and fostering genuine connections with residents of Hong Kong. This was an opportunity to collaborate and design for the Sham Shui Po community.
                            </h3>

                        </div>


                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>1-Minute Summary</h2>

                        </div>

                    </div>

                    {/* Right Column */}
                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                The Problem: The cloth vendors of Pang Jai were displaced from their original location due to government redevelopment and were struggling to sustain their enterprises in their new location.‍
                            </h3>

                            <h3>
                                The Process: We started off by conducting a site visit and researching about the history of Pang Jai, revealing the challenges faced by displaced cloth vendors. Drawing insights from our research and a relevant case study, we created a strategy that focused on improving the market design and conducting collaborative workshops with people in the district, while preserving its unique cultural heritage. We then drafted a proposal and collaborated with the store vendors to create a final design that blends practical interventions with community-driven initiatives to create a vibrant and inclusive space.
                            </h3>

                            <h3>
                                The Solution: We designed a wayfinding system with directories and storefront signages. Using fabrics sold at the market, we created tote bags for store vendors to sell, providing both an additional revenue stream and a means of showcasing their products. Furthermore, we organized a community workshop to create an engaging art installation for the market's front entrance, enhancing its visual appeal and drawing in more visitors.‍
                            </h3>

                        </div>


                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>My Role
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>My role for the project was to work with my team and the vendors to analyze the pain points and problems with Pang Jai, devise a clear plan through a brainstorming session, and design a clear wayfinding experience.

                                I'm grateful for the opportunity to work alongside a brilliant team of Social, Environmental, Product, and Communication Designers.</h3>
                            {/* that blends practical interventions with community-driven initiatives to create a vibrant and inclusive space */}

                        </div>


                    </div>
                </div>
            </div>

            <div className="row align-items-start ">
                <div className="col-lg-4 col-md-12 left-column">
                    <img src={final4} alt="market" className="bottom-image" />


                </div>

                <div className="col-lg-4 col-md-12 left-column">
                    <img src={final2} alt="market" className="bottom-image" />


                </div>

                <div className="col-lg-4 col-md-12 left-column">
                    <img src={final3} alt="market" className="bottom-image" />


                </div>

            </div>

            <div className="row align-items-start ">
                <div className="col-md-12 left-column">
                    <img src={final1} alt="market" className="bottom-image" />


                </div>


            </div>


            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>The Problem
                            </h2>

                        </div>

                    </div>


                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h1>The cloth vendors of Pang Jai were displaced from their original location due to government redevelopment, and since then, struggling to sustain their enterprises in their new location.

                            </h1>
                            <h3>Pang Jai, formerly located in Yen Chow Street Hawker Bazaar, is a fabric market in Shum Shui Po, Hong Kong, that faced displacement as the government reclaimed the land for public housing development. Despite years of fighting for their rights, the vendors were unable to sustain their businesses after relocation. The remaining 16 cloth vendors have been relocated to the nearby Tung Chau Street Temporary Market, but business did not improve. They reached out to the Hong Kong Polytechnic University seeking assistance to revive and support their struggling enterprises.

                            </h3>


                        </div>


                    </div>
                </div>

            </div>





            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>History Analysis</h2>

                        </div>

                    </div>

                    {/* Right Column */}
                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                In order to determine the reasons why they were facing these problems and how we could help, we first had to learn more about the history of Pang Jai in Sham Shui Po, Hong Kong. Today, this area has the highest percentage of eldery residents.
                            </h3>

                        </div>


                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h3>Pre-Colonial Period: Before 1900s
                            </h3>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>Sham Shui Po was small village of mostly rural farmland.</h3>
                        </div>


                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h3>Entrepreneurial Spirit: 1940s - 1960s
                            </h3>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>After World War 2, due to political unrest, people fled to Hong Kong in search of safety and better opportunities. Hong Kong experienced a surge in manufacturing and Sham Shui Po became a hub for small-scale markets, including textiles. The low cost of living and entrepreneurial spirit of Sham Shui Po attracted immigrants who sought to build businesses and create better lives for themselves and their families. This district became a hub for people to start anew, and  many cloth vendors scattered across the neighbourhood fostered a culture of entrepreneurship among its residents.

                            </h3>

                            <div className="row align-items-start ">
                                <div className="col-md-6 left-column">
                                    <img src={history1} alt="market" className="bottom-image" />


                                </div>

                                <div className="col-md-6 left-column">
                                    <img src={history2} alt="market" className="bottom-image" />


                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h3>The Textile Hub: 1960s - 1990s
                            </h3>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>Sham Shui Po evolved into a major trading center for fabrics, textiles, and sewing supplies. It became a destination for locals, designers, and manufacturers seeking affordable materials.
                            </h3>
                            <h3>
                                A grassroots market composed of local small-scale cloth vendors is commonly referred to as “Pang Jai” by the Hong Kong residents. During its peak, it had a total of 192 stalls.
                            </h3>

                            <div className="row align-items-start ">
                                <div className="col-md-6 left-column">
                                    <img src={history3} alt="market" className="bottom-image" />


                                </div>

                                <div className="col-md-6 left-column">
                                    <img src={history4} alt="market" className="bottom-image" />


                                </div>

                            </div>
                        </div>


                    </div>
                </div>


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h3>Relocation: 2013-2017
                            </h3>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>The government approved a construction plan for public housing to be built where the Pang Jai market was located, and requested clearance of the site. As businesses began to close and evacuate, in 2015 remaining hawkers formed a concern group with volunteers, consisting of town planners, students, community organizers, university teachers, designers, and fashion designers, to create a community planning proposal to preserve the Pang Jai market. In 2016, the government confirmed that the market would relocate to a nearby centre. However, new issues arose as the proposed building was built to be a wet market and located in a remote area, thus unsuitable for selling textiles.
                            </h3>

                            <div className="row align-items-start ">
                                <div className="col-md-6 left-column">
                                    <img src={history5} alt="market" className="bottom-image" />


                                </div>

                                <div className="col-md-6 left-column">
                                    <img src={history6} alt="market" className="bottom-image" />


                                </div>

                            </div>
                        </div>


                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h3>Today: 2017 - Present
                            </h3>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>The remaining cloth vendors relocated to Tung Chau Street Temporary Market to continue their businesses.

                            </h3>

                            <div className="row align-items-start ">
                                <div className="col-md-6 left-column">
                                    <img src={history7} alt="market" className="bottom-image" />


                                </div>

                                <div className="col-md-6 left-column">
                                    <img src={history8} alt="market" className="bottom-image" />


                                </div>

                            </div>
                        </div>


                    </div>
                </div>

            </div>





            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>District Statistics
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                To gain insights into potential collaboration partners and understand the district better, we collected current relevant district statistics as references. These statistics will serve as valuable information to inform our decision-making process and help us identify suitable partners for collaboration.
                            </h3>

                            <div className="row align-items-start ">
                                <div className="col-lg-4 col-md-12  left-column">

                                    <img src={householdcomposition} alt="householdcomposition" className="bottom-image" />

                                    <h3>Household Composition, 2023</h3>
                                    <h3>
                                        Nuclear family households refers to a family unit consisting of two parents and their children. Relative households refer to family units that include extended family members beyond the nuclear family.
                                    </h3>


                                </div>

                                <div className="col-lg-4 col-md-12 left-column">
                                    <img src={floorarea} alt="floorarea" className="bottom-image" />


                                    <h3>Floor Area Accommodation</h3>
                                    <h3>
                                        Calculated in square meters (m²).
                                    </h3>

                                </div>

                                <div className="col-lg-4 col-md-12 left-column">
                                    <img src={agecomposition} alt="buildings" className="bottom-image" />
                                    <h3>Age Composition</h3>
                                    <h3>Percentage of population age groups.</h3>

                                </div>
                            </div>
                            <div className="row align-items-start text-content">
                                <h3>Types of Amenities, Services and Buildings in the Area</h3>
                                <div className="col-lg-6 col-md-12 left-column">
                                    <img src={buildings} alt="buildings" className="bottom-image" />
                                </div>
                                <div className="col-lg-6 col-md-12 left-column ">
                                    <Legend />
                                </div>







                            </div>
                        </div>


                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h3>Key Groups

                            </h3>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                Primary Demographics of the Region
                            </h3>
                            <h3>
                                Sidewalk Mat Elderly: Near the area, similar to many parts of Hong Kong with an older population, there are elderly people who sell secondhand goods on the street.
                            </h3>
                            <h3>
                                Low Income Families: Sham Shui Po is known for its relatively lower cost of living compared to other parts of Hong Kong.
                            </h3>
                            <h3>
                                Ethic Minorities: Many minorities spend time in the area weekends.
                            </h3>
                            <h3>
                                Middle Income Families: In recent years, Sham Shui Po has gone through changes due to urban development, including construction of middle class apartments and condominiums in the region.
                            </h3>

                        </div>


                    </div>
                </div>
            </div>





            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h3>
                                Site Analysis
                            </h3>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                To gain a deeper understanding of Pang Jai and its dynamics, we conducted a site visit and chatted with cloth vendors that were present.
                            </h3>
                            <h3>
                                We mapped out what different stalls sold and the names and sections of their stores. Unfortunately many of the vendors were not present, so our original categories were based mostly on speaking to those who were there and what we saw ourselves.
                            </h3>

                        </div>


                        <div className="text-content">

                            <h3>
                                Store Categories
                            </h3>
                            <img src={storecategories} alt="storecategories" className="bottom-image" />
                            <img src={storecategories2} alt="storecategories" className="bottom-image" />

                        </div>


                        <div className="text-content">

                            <h3>
                                Store Names
                            </h3>
                            <img src={storenames} alt="storenames" className="bottom-image" />

                        </div>

                    </div>
                </div>
            </div>







            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>Problem Verification

                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                Through research and conducting a site visit, it became apparent that Pang Jai encountered numerous challenges following their relocation. These factors have significantly impacted the vendors' ability to thrive in their new setting. The current circumstances call for urgent attention and support to address these issues and help Pang Jai regain its momentum and success.
                            </h3>
                            <h3>
                                Sparse Flow of People: Most people were not aware about Pang Jai and it was not well known that they had relocated.
                            </h3>
                            <h3>
                                Confusing Navigation: In the market, some stall vendors lacked clear banners to identify their location, and those that do often had inconsistent and difficult-to-read signage. Furthermore, due to the extensive display of fabric within each stall, vendors often rent multiple stalls and sit among them, making it challenging to identify who to approach when requesting fabric cutting. Banners were placed between stalls rather than infront of individual stalls to maximize fabric display, further complicating navigation.
                            </h3>

                        </div>

                        <div className="row align-items-start ">
                            <div className="col-lg-4 col-md-12 left-column">

                                <img src={problemverification1} alt="problemverification" className="bottom-image" />
                                <h3>
                                    Vendors sit to the right of the stall, since the stall is filled with fabric.
                                </h3>


                            </div>

                            <div className="col-lg-4 col-md-12 left-column">


                                <img src={problemverification2} alt="problemverification" className="bottom-image" />
                                <h3>
                                    Store signage placed in between stalls, however the vendor only owned the left stall.
                                </h3>


                            </div>

                            <div className="col-lg-4 col-md-12 left-column">


                                <img src={problemverification3} alt="problemverification" className="bottom-image" />
                                <h3>
                                    Store signage placed directly above the stall.
                                </h3>

                            </div>




                        </div>
                        <h3>
                            Severely Restricted Business Operations: Regulations and economic challenges led to limitations on what activities were permitted within the market and reduced hours.
                        </h3>
                        <h3>
                            Remote Location: During our initial site visit, it was apparent that the surrounding area is hostile. The building is difficult to find, as it is placed directly under a bridge, and is located next to a sewage screening plant. This issue is also reflected in the poor performance of the nearby Jade Market, established in 2008.

                        </h3>

                        <div className="row align-items-start ">
                            <div className="col-md-6 left-column">

                                <img src={problemverification4} alt="problemverification" className="bottom-image" />
                                <h3>
                                    Side view of the market front entrance.
                                </h3>


                            </div>

                            <div className="col-md-6 left-column">


                                <img src={problemverification5} alt="problemverification" className="bottom-image" />
                                <h3>
                                    Front view of the market front entrance.
                                </h3>


                            </div>




                        </div>
                    </div>


                </div>

            </div>



            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Strategy: The four Ps
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">


                            <h3>
                                We intend to utilize the four Ps in brand strategy to analyze the current situation of Pang Jai. By examining the Product, Price, Promotion, and Place aspects of the market, we can gain valuable insights into its positioning, offerings, pricing, communication, and distribution, aiding us in developing effective strategies for its revitalization.
                            </h3>

                            <h3>
                                Product: Pang Jai offers a variety of cloth-related products. It is crucial to assess the quality, range, and uniqueness of the products to ensure they meet the changing demands and preferences of customers.
                            </h3>

                            <h3>
                                Price: Considering the challenges faced by Pang Jai, it is essential to evaluate the pricing strategy. This includes examining the affordability and competitiveness of the products to attract customers despite the limitations in the surrounding environment and sparse foot traffic.
                            </h3>

                            <h3>
                                Promotion: Pang Jai needs an effective promotional strategy to create awareness and generate interest. Innovative marketing campaigns, workshops, and collaborations with relevant influencers or organizations can help reach a wider audience and revitalize customer engagement.
                            </h3>

                            <h3>
                                Place: The remote location and subpar surrounding environment have been adversely affected Pang Jai. To overcome these challenges, improving accessibility through transportation links and signage can attract more visitors.
                            </h3>



                        </div>
                    </div>


                </div>

            </div>






            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Initial Proposal
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">


                            <h3>
                                Through our research on the history of Pang Jai, different user groups, and the Grand Bazaar case study, we outlined our next steps moving forward.
                            </h3>

                            <h3>
                                <ul>
                                    <li>
                                        Design Concept: We needed to determine our brand for Pang Jai.
                                    </li>
                                    <li>
                                        Categorization System: We needed to create a way for visitors to efficiently find their goods.

                                    </li>
                                    <li>
                                        Wayfinding System: We needed to create a wayfinding system within the market.
                                    </li>
                                </ul>
                            </h3>

                            <h3>
                                After speaking to vendors with this proposal, we can then consider how to create a finalized design and how to attract visitors and different groups in the surrounding area.
                            </h3>



                        </div>

                        <div className="text-content">


                            <h3>
                                Concept 1
                                <h3>
                                </h3>
                                Centres on uniqueness, community, and indie spirit: To convey the sense of community, we used a bright spring palette to show friendliness and connection. Vibrant magenta is used as the main color, which accentuates the unique personality of Pang Jai.
                            </h3>
                            <div className="row align-items-start ">
                                <div className="col-md-6 left-column">
                                    <img src={color1} alt="color" className="bottom-image" />
                                </div>
                            </div>



                        </div>

                        <div className="text-content">


                            <h3>
                                Concept 2
                                <h3>
                                </h3>
                                Visuals were inspired by the layering of translucent fabrics. Image references chosen are used to convey that feeling, and simple organic line drawings are used for the local atmosphere.
                            </h3>
                            <div className="row align-items-start ">
                                <div className="col-md-6 left-column">
                                    <img src={color2} alt="color" className="bottom-image" />
                                </div>
                            </div>

                        </div>


                        <div className="text-content">


                            <h3>
                                Concept 3
                                <h3>
                                </h3>
                                As the initial idea for concept 3 is to bring and show the new idea of Pang Jai, the design and reference graphics represent new design trends and graphic ideas. Visuals are bright, geometric, and structured to a grid.
                            </h3>
                            <div className="row align-items-start ">
                                <div className="col-md-6 left-column">
                                    <img src={color3} alt="color" className="bottom-image" />
                                </div>
                            </div>

                        </div>


                    </div>


                </div>

            </div>


            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Categorization System
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">


                            <h3>
                                To counter the problem of a lack of organization where different product types were sold, we created a categorization system based on the current placement. We proposed a set of icons that reflected each product category.
                            </h3>



                        </div>
                        <div className="row align-items-start">
                            <div className="col-md-12 col-lg-8 left-column">
                                <img src={icons} alt="icons" className="bottom-image" />
                            </div>
                        </div>


                    </div>


                </div>

            </div>


            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Wayfinding System
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">


                            <h3>
                                After the initial site visit, it became apparent that there needed to be some sort of signage system to help customers navigate upon walking to the market. We worked together to determine where different signages should be, considering where they would first enter.
                            </h3>

                            <h3>
                                Yellow: Entrances
                            </h3>
                            <h3>
                                Blue: Individual Store Signage
                            </h3>
                            <h3>
                                Orange: Directory Signage
                            </h3>
                            <img src={signage} alt="signage" className="bottom-image" />

                        </div>



                        <div className="text-content">
                            <div className="row align-items-start ">
                                <h3>
                                    Individual Store Signage
                                    <h3>
                                    </h3>
                                    Our idea was that these signs would be placed next to the poles between store blocks, since this was the only space we could use without blocking the products. The signage would show the type of products offered as well.
                                </h3>

                                <div className="col-md-6 left-column">

                                    <img src={signagemockup1} alt="signagemockup" className="bottom-image" />
                                    <h3>
                                        Option 1
                                    </h3>


                                </div>

                                <div className="col-md-6 left-column">


                                    <img src={signagemockup2} alt="signagemockup" className="bottom-image" />
                                    <h3>
                                        Option 2
                                    </h3>


                                </div>
                            </div>



                        </div>

                        <div className="text-content">


                            <h3>
                                Directory Signage
                                <h3>
                                </h3>
                                As several vendors were not present during our initial visit, our current directory system may be missing businesses and products. Because of this, we wanted to ask the vendors if this categorization system is correct and how they would want their store to be shown. We printed a copy of the maps shown above for our proposal.
                            </h3>

                        </div>
                    </div>


                </div>

            </div>


            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Initial Feedback from Stall Owners
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">


                            <h3>
                                At this stage, we hoped to show our proposal to different stall owners at Pang Jai to receive their feedback. Mr. Ho, the stall owner who often advised our project, liked the color scheme of concept 1, saying the brighter and more eye-catching the colors, the better.
                            </h3>

                            <h3>
                                In regards to the activities, Mr. Ho suggested that the contents of the proposed market should be relevant to Pang Jai and promote the purchase of fabric. It was a good idea for Pang Jai to host collection points for online shops, since they already had a store that mainly operated by self-service. Upon the suggestion of making social media for Pang Jai, Mr. Ho stressed the importance of the social media page promoting the entirety of Pang Jai and not just individual stores, as they are a collective and should not stoke competition.
                            </h3>

                            <h3>
                                For the proposed product designs, Mr. Ho revealed that Pang Jai had previously printed promotional t-shirts and they still had a lot of it in stock, and advised against creating more. However, a previous promotional tote bag campaign had much greater success and was well received by the public due to the practicality of the product.
                            </h3>

                            <h3>
                                For other decorations, Mr. Ho suggested we could items on the columns in Pang Jai that could easily be removed. We could integrate the cloth decoration and the historical wall, and intersperse them along the metal fencing outside. Our trolley idea was good, but it couldn’t be kept too far outside as it had a higher chance of being stolen. Instead, the trolley could be an interactive activity, such as letting passersby drop in supportive notes, and it could also act as a platform for promoting design concepts and products.
                            </h3>

                            <h3>
                                Mr. Ho and many of the other stall owners brought up the public safely and obstruction policies enforced by the government authorities, which may cause the floor signages to be subjected for removal. They also remarked that the shop sign totems may be easily damaged from carts and moving operations in the market. It would be better for them to be hung on the light beams instead.
                            </h3>

                            <h3>
                                They suggested that the decorations could be hung on a cloth on a curtain rail so it could be retracted at night and pulled out in the daytime. However, they said we should avoid working with electricity for outside decorations as electrical wiring was difficult to manage and get permissions for.
                            </h3>

                        </div>

                        <div className="text-content">
                            <div className="row align-items-start ">


                                <div className="col-md-6 left-column">

                                    <img src={sitevisit1} alt="sitevisit" className="bottom-image" />
                                    <h3>
                                        Debrief
                                    </h3>


                                </div>

                                <div className="col-md-6 left-column">


                                    <img src={sitevisit2} alt="sitevisit" className="bottom-image" />
                                    <h3>
                                        Chatting with stall owner
                                    </h3>


                                </div>
                            </div>



                        </div>
                    </div>
                </div>
            </div>


            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Updated Design
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                Based on this feedback, we designed a hanging signage and made sure that the parts could be easily removable.
                            </h3>

                            <img src={sitevisit3} alt="sitevisit" className="bottom-image" />

                        </div>

                        <div className="text-content">

                            <h3>
                                We then created a directory design.
                            </h3>
                            <img src={directory1} alt="directory" className="bottom-image" />
                            <img src={directory2} alt="directory" className="bottom-image" />

                        </div>

                        <div className="text-content">

                            <h3>
                                To promote Pang Jai, we decided to host a co-creation workshop to create two dream catchers and a banner, using left over materials from Mr. Ho. This event was promoted on social media and posted around the area.
                            </h3>
                            <div className="row align-items-start ">
                                <div className="col-md-12 col-lg-6">

                                    <img src={workshop} alt="workshop" className="bottom-image" />
                                </div>
                                <div className="col-md-12 col-lg-6">

                                    <img src={workshop2} alt="workshop" className="bottom-image" />
                                </div>
                            </div>

                            <h3>
                                The product designer on our team created tote bags, as suggested by Mr. Ho, by silk screening the map of Pang Jai onto the fabrics.
                            </h3>

                            <div className="row align-items-start ">


                                <div className="col-md-6 left-column">

                                    <img src={bags1} alt="bags" className="bottom-image" />
                                    <h3>
                                        Working with Mr. Ho to silkscreen the bags
                                    </h3>


                                </div>

                                <div className="col-md-6 left-column">


                                    <img src={bags2} alt="bags" className="bottom-image" />
                                    <h3>
                                        Bag samples
                                    </h3>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>



            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Directory Feedback from Stall Owners
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                Jo and I visited the store owners again to confirm three things.
                            </h3>

                            <h3>
                                1. We wanted to confirm again if the stores and categorization system was correct.
                            </h3>

                            <h3>
                                2. We wanted to ask the owners about the design system. Because there are a wide variety of fabrics and products in Pang Jai, we had chosen to use a darker background with bright colours for our designs.
                            </h3>

                            <h3>
                                3. We wanted to check if the directory was legible and easy to see when placed within the market.
                            </h3>

                            <h3>
                                After speaking with Mr. Ho, he walked us around the market to speak with all the store owners. Through this session, we realized that many of the stores were different than what we had initially observed, and almost all the stores had no visible signage indicating who to speak to.
                            </h3>


                        </div>


                        <div className="text-content">


                            <div className="row align-items-start ">


                                <div className="col-lg-4 col-md-12 left-column">

                                    <img src={directoryfeedback1} alt="directoryfeedback" className="bottom-image" />
                                    <h3>
                                        Speaking to Mr. Ho
                                    </h3>


                                </div>

                                <div className="col-lg-4 col-md-12 left-column">


                                    <img src={directoryfeedback2} alt="directoryfeedback" className="bottom-image" />
                                    <h3>
                                        Map after speaking with store owners
                                    </h3>


                                </div>

                                <div className="col-lg-4 col-md-12 left-column">

                                    <img src={directoryfeedback3} alt="directoryfeedback" className="bottom-image" />
                                    <h3>
                                        Measuring the directory placement
                                    </h3>


                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>



            <div className="large-content">


                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Workshops and Community Engagement
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                We aim to foster an interconnected relationship between the local residents and the community in Pang Jai. Our plan is to make Pang Jai more eye-catching from the inside out, making it easily recognizable to the public even though it is located under the bridge. Based on the concept of interwoven into one, our social design students decided to organize a fabric workshop to attract participants to learn about sheds and achieve the concept of co-creation.
                            </h3>

                            <h3>
                                We first asked Mr. Ho to collect old fabrics for us to prototype. In order to achieve the interweaving design, we tried to cut the old fabrics into strips and weave them on the wooden frame. As the strips of different colors and textures were interwoven, together it will become an art installation with rich colors and textures, achieving the effect of "into one".
                            </h3>


                        </div>


                        <div className="text-content">


                            <div className="row align-items-start ">


                                <div className="col-lg-6 col-md-12 left-column">

                                    <img src={community1} alt="community" className="bottom-image" />



                                </div>

                                <div className="col-lg-6 col-md-12 left-column">


                                    <img src={community2} alt="community" className="bottom-image" />



                                </div>


                            </div>
                            <div className="row align-items-start ">


                                <div className="col-lg-6 col-md-12 left-column">

                                    <img src={community3} alt="community" className="bottom-image" />



                                </div>

                                <div className="col-lg-6 col-md-12 left-column">


                                    <img src={community4} alt="community" className="bottom-image" />



                                </div>


                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className="large-content">
                <div className="row align-items-start ">
                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={final4} alt="market" className="bottom-image" />


                    </div>

                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={final2} alt="market" className="bottom-image" />


                    </div>

                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={final3} alt="market" className="bottom-image" />


                    </div>

                </div>

                <div className="row align-items-start ">
                    <div className="col-md-12 left-column">
                        <img src={final1} alt="market" className="bottom-image" />


                    </div>


                </div>
                <div className="row align-items-start ">
                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={final5} alt="market" className="bottom-image" />


                    </div>

                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={final6} alt="market" className="bottom-image" />


                    </div>

                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={final7} alt="market" className="bottom-image" />


                    </div>

                </div>

            </div>
















            <div className="large-content">
                <div className="row align-items-start large-content">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>Reflecting</h2>
                        </div>
                    </div>


                    <div className="col-md-8 right-column ">

                        <div>
                            <div className="text-content">

                                <h3>
                                    The Pang Jai project aimed to revitalize market infrastructure to attract customers, enhance browsing experiences, and promote traditional Chinese culture. The collaborative project involved working with store owners and designers across multiple disciplines, including digital flyers, print, product design, and wayfinding. This teamwork led to innovative solutions by integrating diverse perspectives and skills throughout the research and design phases. The ultimate goal was to create design elements that not only serve functional purposes but also amplify Pang Jai's community spirit, contributing to its preservation and cultural significance within Hong Kong.
                                </h3>

                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <div className="large-content">
                <div className="row align-items-start large-content">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>An Ongoing Project</h2>
                        </div>
                    </div>


                    <div className="col-md-8 right-column ">

                        <div>
                            <div className="text-content">

                                <h3>
                                    Our project was approved for funding from the government in 2024. Part of my team is currently working on with the store owners on the physical wayfinding. In the meantime, we have the prototype of the directory maps on display at the set locations to use, as well as the fabric workshop dream catcher set up at the front entrance. Last week (upon writing this it is March 28th), my team put up some designs of the silkscreened bag on the walls in-between the stall sections. Mr. Ho said that a lot of people asked if they were for sale, so the team plans on making more for the vendors to sell themselves.
                                </h3>

                            </div>

                        </div>
                    </div>
                </div>

            </div>


            <div className="large-content">
                <div className="row align-items-start ">
                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={additionalprocess1} alt="additionalprocess" className="bottom-image" />


                    </div>

                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={additionalprocess2} alt="additionalprocess" className="bottom-image" />


                    </div>
                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={additionalprocess3} alt="additionalprocess" className="bottom-image" />


                    </div>

                </div>
                <div className="row align-items-start ">

                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={additionalprocess5} alt="additionalprocess" className="bottom-image" />


                    </div>
                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={additionalprocess6} alt="additionalprocess" className="bottom-image" />


                    </div>

                    <div className="col-lg-4 col-md-12 left-column">
                        <img src={additionalprocess4} alt="additionalprocess" className="bottom-image" />


                    </div>

                </div>



            </div>
        </div >
    );
};

export default Home;
