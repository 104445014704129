import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { Container, Row, Col } from 'react-bootstrap';
import './About.css';  // Import custom styles
import './Home.css';
import './Content.css';
function About() {
    const svgRef = useRef(null);

    useEffect(() => {
        // Function to calculate square size based on window dimensions
        const calculateSquareSize = () => {
            const margin = 40;
            const windowWidth = window.innerWidth - margin * 2;
            const windowHeight = window.innerHeight - margin * 2;
            let size;

            if (windowWidth < 600) {
                size = 600; // Small size for small screens
            } else if (windowWidth < 900) {
                size = 900; // Medium size for medium screens
            } else {
                size = 950; // Large size for large screens
            }

            return size;
        };

        const wrapText = (selection, width) => {
            selection.each(function () {
                const element = d3.select(this);
                const words = element.text().split(/\s+/).reverse();
                let line = [];
                const lineHeight = 1.1; // em units
                const dy = parseFloat(element.attr("dy")) || 0;

                // Clear existing text
                element.text(null);

                // Append the first tspan
                let tspan = element.append("tspan")
                    .attr("x", 0) // Relative to the text's transform
                    .attr("dy", dy + "em");

                let word;
                while ((word = words.pop())) {
                    line.push(word);
                    tspan.text(line.join(" "));
                    if (tspan.node().getComputedTextLength() > width) {
                        line.pop();
                        tspan.text(line.join(" "));
                        line = [word];
                        tspan = element.append("tspan")
                            .attr("x", 0) // Align with parent text's x
                            .attr("dy", lineHeight + "em") // Move to next line
                            .text(word);
                    }
                }
            });
        };

        // Function to draw the graph
        const drawGraph = () => {
            const svg = d3.select(svgRef.current);

            const size = calculateSquareSize();

            // Clear the SVG before drawing
            svg.selectAll('*').remove();

            // Set the SVG's viewBox correctly using template literals
            svg.attr("viewBox", `0 0 ${size} ${size}`)
                .attr("preserveAspectRatio", "xMidYMid meet")
                .style("width", "100%") // Ensure SVG takes full width of container
                .style("height", "auto"); // Height adjusts automatically based on aspect ratio

            const nodes = [
                { id: 1, label: "Studied at the OCAD University" },
                { id: 2, label: "Worked as a Conversation and UX Designer at RBC" },
                { id: 3, label: "Worked as a UX Designer at Nokia" },
                { id: 4, label: "Studied abroad at the Hong Kong Polytechnic University" },
                { id: 5, label: "Working as an Interaction Designer/Researcher at Huawei R&D labs" },
                { id: 6, label: "Worked as a Designer at Jack/Cover Health" },
                { id: 7, label: "Co-redesigned the Pang Jai Fabric Market" },
                { id: 11, label: "Co-founded the UX Student Association" },
                { id: 12, label: "Founded the OCADU Undergraduate Research Association" }
            ];

            const links = [
                { source: 1, target: 2 },
                { source: 2, target: 3 },
                { source: 3, target: 4 },
                { source: 4, target: 5 },
                { source: 1, target: 6 },
                { source: 4, target: 7 },
                { source: 1, target: 11 },
                { source: 1, target: 12 },
            ];

            // Determine wrapWidth based on size
            const getWrapWidth = () => {
                if (size < 600) return 100;
                if (size < 900) return 160;
                return 180;
            };
            const wrapWidth = getWrapWidth();

            // Scale simulation parameters based on size
            const linkDistance = size / 4; // Adjust link distance proportionally
            const chargeStrength = -size * 0.3; // Adjust charge strength proportionally
            const collisionRadius = size * 0.1; // Adjust collision radius proportionally

            // Main force simulation for nodes and links
            const simulation = d3.forceSimulation(nodes)
                .force("link", d3.forceLink(links).id(d => d.id).distance(linkDistance))  // Scaled distance
                .force("charge", d3.forceManyBody().strength(chargeStrength))  // Scaled repulsion
                .force("center", d3.forceCenter(size / 2, size / 2))
                .force("collision", d3.forceCollide().radius(collisionRadius))  // Scaled collision radius
                .on("tick", ticked);

            // Create link elements
            const linkSelection = svg.append("g")
                .attr("class", "links")
                .selectAll("line")
                .data(links)
                .enter().append("line")
                .attr("class", "link");

            // Create node elements
            const nodeSelection = svg.append("g")
                .attr("class", "nodes")
                .selectAll("circle")
                .data(nodes)
                .enter().append("circle")
                .attr("class", "node")
                .attr("r", size * 0.006) // Scale node radius based on size
                .call(d3.drag()
                    .on("start", dragStarted)
                    .on("drag", dragged)
                    .on("end", dragEnded))
                .on("mouseover", function (event, d) {
                    simulation.alphaTarget(0.3).restart();
                    d.fx = d.x - (Math.random() * 10 - 5);  // Slight random move on hover
                    d.fy = d.y - (Math.random() * 10 - 5);
                })
                .on("mouseout", function (event, d) {
                    simulation.alphaTarget(0);
                    d.fx = null;
                    d.fy = null;
                });

            // Create text labels with transform for positioning
            const labelSelection = svg.append("g")
                .attr("class", "labels")
                .selectAll("text")
                .data(nodes)
                .enter().append("text")
                .attr("transform", d => `translate(${d.x}, ${d.y})`) // Corrected template literals
                .attr("dx", size * 0.03)        // Scale horizontal offset based on size
                .attr("dy", ".35em")   // Vertical alignment remains the same
                .attr("font-family", 'Neue Haas Unica W1G, sans-serif')
                .attr("font-size", `${size * 0.015}rem`) // Scale font size based on size
                .attr("fill", "white")
                .attr("fill-opacity", d => d.id === 5 ? 1 : 0.7) // Set opacity conditionally
                .text(d => d.label)
                .call(wrapText, wrapWidth);

            // Function to update positions on each tick of the simulation
            function ticked() {
                linkSelection
                    .attr("x1", d => d.source.x)
                    .attr("y1", d => d.source.y)
                    .attr("x2", d => d.target.x)
                    .attr("y2", d => d.target.y);

                nodeSelection
                    .attr("cx", d => d.x = Math.max(20, Math.min(d.x, size - 20)))  // Clamp x position with padding
                    .attr("cy", d => d.y = Math.max(20, Math.min(d.y, size - 20))); // Clamp y position with padding

                labelSelection
                    .attr("transform", d => `translate(${d.x}, ${d.y})`); // Corrected template literals
            }

            // Drag functions to allow nodes to be dragged
            function dragStarted(event, d) {
                if (!event.active) simulation.alphaTarget(0.3).restart();
                d.fx = d.x;
                d.fy = d.y;
            }

            function dragged(event, d) {
                d.fx = Math.max(20, Math.min(event.x, size - 20));  // Clamp x position
                d.fy = Math.max(20, Math.min(event.y, size - 20));  // Clamp y position
            }

            function dragEnded(event, d) {
                if (!event.active) simulation.alphaTarget(0);
                d.fx = null;
                d.fy = null;
            }

            // Start the simulation
            simulation.nodes(nodes);
            simulation.force("link").links(links);
            simulation.alpha(1).restart();
        };

        // Initial draw and setup resize listener with debounce
        drawGraph(); // Call drawGraph initially

        // Debounce timeout
        let resizeTimeout;

        // Handle window resize with debounce
        const handleResize = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                drawGraph();
            }, 300); // 300ms debounce delay
        };

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup on unmount
        return () => {
            window.removeEventListener('resize', handleResize);
            clearTimeout(resizeTimeout);
        };
    }, []);



    return (
        <div className='background'>
            <Container className="about-container">
                <Row>
                    <Col lg={5} md={12} className="d-flex flex-column align-items-start">
                        <h1>Hi there :P</h1>
                        <h3>
                            I am an Interaction Designer and Researcher at <b>Huawei’s R&D Human Machine Interaction lab</b>. I explore  future possibilities of technology can evolve digital and physical experiences by implementing user interfaces, haptics, voice, text, and gesture solutions.
                            Previously, I worked on user experiences at the <b>Royal Bank of Canada</b> and <b>Nokia</b>.
                        </h3>
                        <br></br>
                        <h3>
                            On the side, I am the President of the OCADU Undergraduate Technology Research Association and Co-Founder of the UX Student Association. I like to play electric guitar and work on creative coding projects in my spare time.
                        </h3>
                        <br></br>
                        <h3 style={{ fill: '#b4b4b4' }}>
                            <b>Skills:</b> Javascript, Python, C++, ComfyUI, Figma, Sketch, Adobe Creative Suite
                        </h3>
                        <h3 style={{ fill: '#b4b4b4' }}>
                            <b>Interests:</b>  Human-computer interaction, Speculative Design, Visual programming
                        </h3>

                    </Col>
                    <Col lg={7} md={12} className="d-flex justify-content-end align-items-end">
                        <svg
                            ref={svgRef}
                        ></svg>
                    </Col>
                </Row>
            </Container>
        </div >
    );
}

export default About;