import React from 'react';
import './Home.css';
import './Content.css';
import demo1 from '../assets/nokia/example1.png';
import demo2 from '../assets/nokia/example2.png';
import demo3 from '../assets/nokia/example3.png';
import demo11 from '../assets/nokia/example11.png';
import demo12 from '../assets/nokia/example12.png';
import demo13 from '../assets/nokia/example13.png';


const Home = () => {

    return (

        <div className="container content">

            <div className="row align-items-start ">
                {/* <div className="col-lg-6 col-md-12 left-column">
                    <img src={cover1} alt="cover" className="bottom-image" />
                </div> */}

                <div className="col-lg-12 col-md-12 left-column">
                    {/* <img src={cover} alt="cover" className="bottom-image" /> */}
                </div>
            </div>

            <div className="row justify-content-center">
                <div className="col-md-8">

                    <div className="text-content">

                        <h2>UX/UI Design</h2>
                        <h1>Nokia Design System</h1>
                        {/* <h3>Personal Project</h3> */}

                    </div>



                </div>

            </div>

            <div className="row justify-content-center">
                <div className="col-md-8">

                    <div className="text-content">
                        <h2>Following Nokia's rebrand in 2023, I collaborated with designers and developers to rebuild Nokia’s design system to reflect the company’s present-day offerings as a business-to-business network provider. </h2>

                    </div>

                    <img src={demo1} alt="demo" className="bottom-image" />
                    <img src={demo12} alt="demo" className="bottom-image" />
                    <img src={demo13} alt="demo" className="bottom-image" />
                    <img src={demo2} alt="demo" className="bottom-image" />
                    <img src={demo11} alt="demo" className="bottom-image" />
                    <img src={demo3} alt="demo" className="bottom-image" />

                </div>

            </div>






        </div >
    );
};

export default Home;
