import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import ProximityUI from './components/proximityui';
import RBCTia from './components/rbctia';
import Pangjai from './components/pangjai';
import Quanne from './components/quanne';
import Navbar from './components/Navbar'; // Import the Navbar component
import Blink from './components/other';
import Thinking from './components/creativity';
import Nokia from './components/nokia';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/proximityUI" element={<ProximityUI />} />
          <Route path="/pangjai" element={<Pangjai />} />
          <Route path="/rbctia" element={<RBCTia />} />
          <Route path="/quanne" element={<Quanne />} />
          <Route path="/blink" element={<Blink />} />
          <Route path="/thinking" element={<Thinking />} />
          <Route path="/nokia" element={<Nokia />} />
        </Routes>
      </div>
    </Router>
  );
}


const container = document.getElementById('root');

// Create a root.
const root = ReactDOM.createRoot(container);

// Initial render
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export default App;
