import React from 'react';
import './Home.css';
import './Content.css';
import cover from '../assets/Thinking/cover.png';
import creativeflow from '../assets/Thinking/CreativeFlow.png';
import demo from '../assets/Thinking/demoui.mov';
import gesuredemo from '../assets/Thinking/demo-3-final.mov';
import toolcreationflow from '../assets/Thinking/ToolCreationFlow.png';
import pt1 from '../assets/Thinking/pt-1.png';
import pt2 from '../assets/Thinking/pt-2.png';
import pt3 from '../assets/Thinking/pt-3.png';
import pt4 from '../assets/Thinking/pt-4.png';
import example from '../assets/Thinking/example1.png';
import example2 from '../assets/Thinking/example2.mov';
import example3 from '../assets/Thinking/example3.png';


const Home = () => {

    return (

        <div className="container content">

            <div className="row align-items-start ">
                {/* <div className="col-lg-6 col-md-12 left-column">
                    <img src={cover1} alt="cover" className="bottom-image" />
                </div> */}

                <div className="col-lg-12 col-md-12 left-column">
                    <img src={cover} alt="cover" className="bottom-image" />
                </div>
            </div>

            <div className="row align-items-start">
                <div className="col-md-12 left-column">

                    <div className="text-content">

                        <h2>Research, Development</h2>
                        <h1>Building a tool that uses AI to boost rather than disrupt creativity</h1>
                        <h3>Personal Project</h3>
                    </div>

                </div>

            </div>





            <div className="large-content-first">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>The Problem
                            </h2>

                        </div>

                    </div>


                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h2>
                                Artificial intelligence has become a major collaborator in creative processes, disrupting idea formation for all.
                            </h2>
                            <h3>
                                Every day, we experience media that influences our thoughts and actions.

                            </h3>

                            <h3>
                                A common example of these influences is the predictive text shown when using a keyboard; while this feature is minor, the suggestions shown lead you to change the way you respond to messages. In the 2010s, during the rise of the internet, social media platforms started to change their algorithms, altering user perception and relationships towards creators. A creative example of these influences would be Pinterest, where the algorithm is tailored around the user and returns content in a consistent manner and idealized style.
                            </h3>

                            <h3>
                                With the developments of AIGC in the last few years, we experienced the rise of predictive text-to-image and text-to-text solutions during our day-to-day tasks. However, AI features have been primarily non-deterministic, as they are used to generate varying content based on predictive text. While it is helpful for AI to be able to interpret what we say more easily, I am equally concerned that its widespread use may stifle creativity. As people start to rely on AI to generate new ideas and solutions, there’s a risk that our focus will shift toward concepts formed through patterns of previous data that AI deems creative.
                            </h3>
                        </div>


                    </div>
                </div>

            </div>


            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>Problem Statement
                            </h2>

                        </div>

                    </div>


                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                How can we incorporate AI in our design thinking process to assist rather than hinder our creative thinking?
                            </h3>
                        </div>


                    </div>
                </div>

            </div>




            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>The Design Process
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">
                    </div>

                </div>


            </div>

            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>For humans, where does creativity come from?
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                Creativity is defined as the interconnection of seemingly unrelated ideas in a way that is original and valuable. Human creativity generally relies on prior knowledge of various domains, personal experiences, memories, and our current perceptions. Both an overload of information and a lack of it can hinder creativity, and the level of creativity varies between people.
                            </h3>
                            <h3>
                                According to <i>The Creative Brain </i>, creative thinking is supported by our ability to envision the future by looking into past events. There are two parts of this process, “idea generation” and “idea evaluation” [1].
                            </h3>
                            <h3>
                                <b>Idea generation</b> involves letting our minds drift into new thoughts by relaxing, allowing for ideas that may not come to mind otherwise.
                            </h3>
                            <h3>
                                <b> Idea evaluation</b> involves thinking about what we know and questioning if these ideas would be effective.
                            </h3>
                        </div>
                        <div className="text-content">
                            <h3>
                                Here is a user flow of being creative.

                                <img src={creativeflow} alt="creativeflow" className="bottom-image" />
                            </h3>
                        </div>
                    </div>
                </div>


            </div>

            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                What is currently used for creativity?
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h3>
                                In our current industry, mind maps and brainstorming software applications are commonly used to form ideas. This practice connects both idea generation and idea evaluation, as this visual method can lead to freely connecting thoughts through its varying, informal structure, while ideas can be further analyzed and broken down to assess their level of effectiveness.



                            </h3>
                            <h3>
                                These applications help improve understanding of complex tasks by allowing a focus on different parts and specific aspects. Information is also organized, making it easier to reference and remember.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>

            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                What is the problem with digital brainstorming applications today?
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">
                            <h2>
                                Use of AI to generate and form connections between ideas
                            </h2>
                            <h3>
                                Recently, mind mapping applications have begun to implement AI for automatic clustering, idea generation, and text summarization. This leads to unoriginal concepts during the brainstorming and evaluation part of the creativity process.
                            </h3>
                        </div>
                        <div className="text-content">
                            <h2>
                                Lack of Organization
                            </h2>
                            <h3>
                                Although these applications are useful for visual comprehension, as more items are added to the canvas, they can become cluttered and difficult to follow.
                            </h3>
                        </div>
                        <div className="text-content">
                            <h2>
                                Software Limitations
                            </h2>
                            <h3>
                                These applications have features that try to be tailored to all sorts of users, leading to drawbacks for specific use cases.
                            </h3>
                        </div>
                    </div>
                </div>
            </div>



            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Where can AI help?
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">

                            <h3>
                                Artificial intelligence can enhance the creative process by generating tools that customize the application to fit their personal brainstorming style. As mind mapping tools enable creative people to use <b>visual senses</b> to perceive information, having the software <b>visually adapt</b> to their needs can encourage them to explore ideas without being limited by software.</h3>

                        </div>

                    </div>
                </div>
            </div>

            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                Tool Creation
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">

                            <h3>
                                To maintain a consistent style for elements, I developed a basic design for images, text, and shapes.
                            </h3>
                            <h3>
                                The user is given three types of actions
                                <ul>
                                    <li>
                                        Button that adds blocks of text
                                    </li>
                                    <li>
                                        Button that adds line connectors that can be used with any element on the board
                                    </li>
                                    <li>
                                        Users can generate button actions on the right side, which is automatically placed next to these buttons.
                                    </li>
                                </ul>
                            </h3>
                            <div className="row align-items-start">
                                <div className="col-lg-6 col-md-12">
                                    <img src={pt2} alt="cover" className="bottom-image" />
                                </div>
                                <div className="col-lg-6 col-md-12">
                                    <img src={pt4} alt="cover" className="bottom-image" />
                                </div>
                            </div>


                            {/* 
                            <h3>
                                When creating a tool, there are two steps that need to be defined. First, the creative must define how this tool should be triggered. Second, they must state the function of the tool.
                            </h3>

                            <h3>
                                To design this flow, I have selected two forms of common visual interaction — graphical user interfaces and hand gestures. As the creative is already interacting with the interface to brainstorm, using the interface components on the screen leads to seamless interactions that do not interrupt their thoughts. I chose to use hand gesture interaction because it is a natural way of visual communication. For example, we can use our hands to wave, convey different numbers, or indicate if something is good or bad, etc.
                            </h3>
                            <img src={toolcreationflow} alt="tool creation" className="bottom-image" /> */}
                        </div>

                    </div>
                </div>
            </div>

            <div className="large-content">
                <div className="row align-items-start">
                    <div className="col-md-4 left-column">

                        <div className="text-content">
                            <h2>
                                My Reflection
                            </h2>

                        </div>

                    </div>

                    <div className="col-md-8 right-column ">

                        <div className="text-content">

                            <h3>
                                This project led me to reflect on my current creative process and reconnect with a few of my past colleagues, which led to a discussion on their opinions about AI. There is a general concern that AI will affect the value of humans and lead to a future where all human-made text, voice, image, and video will become buried under layers and layers of AI-generated content. With the increasing disruptions within many industries, I hope we will be prepared for this change to ensure the future supports the creativity of human thinking.
                            </h3>
                            <h3>
                                {/* <i>The source code for this project will be available soon.</i> */}
                            </h3>
                        </div>

                    </div>
                </div>
            </div>


            <div className="large-content">
                <div className="row align-items-start large-content">
                    <h2>
                        Experiments
                    </h2>
                    <div className="text-content">

                        <h3>
                            Topic: Cycles
                        </h3>
                        <h3>
                            Generated Code: Uploading images, rotating text, and adding spirals.
                        </h3>
                        <video src={example2} className="img-fluid w-100" controls loop muted>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    <div className="text-content">
                        <h3>
                            Topic: Limiting 2D Form
                        </h3>
                        <h3>
                            Generated Code: Adding interactive pixel art boards and tic-tac-toe boards
                        </h3>

                        <img src={example3} alt="tool creation" className="bottom-image" />
                    </div>
                    {/* <h3>
                        Custom <b>UI </b>to trigger actions
                    </h3>
                    <video src={demo} className="img-fluid w-100" controls loop muted>
                        Your browser does not support the video tag.
                    </video>

                </div>
                <div className="row align-items-start large-content">
                    <h3>
                        Custom <b>hand gestures</b> to trigger actions
                    </h3>
                    <video src={gesuredemo} className="img-fluid w-100" controls loop muted>
                        Your browser does not support the video tag.
                    </video> */}

                </div>         <h4>1. Beaty, Roger E. “The Creative Brain.” Cerebrum : the Dana forum on brain science vol. 2020 cer-02-20. 1 Jan. 2020</h4>



            </div>




        </div >
    );
};



export default Home;
